<template>
  <BaseAlertTemplate
    :detailedData="detailedData"
    :settingsData="settingsData"
    :algoStrategyData="algoStrategyData"
    :alert="alert"
  >
  </BaseAlertTemplate>
</template>

<script>
import BaseAlertTemplate from './Base/BaseAlertTemplate.vue'
import { getAutoplugRuleInfo } from '@/components/AlertingComponents/Sheet/SheetFragments/AlertTypeTemplate/TemplateCommonLogic/commonLogicAutoplugAlert'
export default {
  name: 'IoHasBlockingEditableProperties',
  props: ['alert'],
  components: {
    BaseAlertTemplate
  },
  data: function () {
    return {
      detailedData: [
        {
          label: 'Company name',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(alert.data.company_name)
          }
        },
        {
          label: 'Autoplug name',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(alert.data.autoplug_name)
          }
        },
        {
          label: 'Rule id',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(alert.data.autoplug_id)
          }
        },
        {
          label: 'Template brief id',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(alert.data.template_brief_id)
          }
        },
        {
          label: 'Autoplug rule',
          value: (alert) => {
            return this.$commonUtils.htmlEntities(getAutoplugRuleInfo(alert))
          }
        }
      ],
      settingsData: [
      ],
      algoStrategyData: [
      ]
    }
  }
}
</script>

<style scoped>

</style>
